.picklist-filters {
  &__col {
    width: calc(50% - 20px);
  }

  &__row {
    width: 100%;
    margin-bottom: 20px;
  }
}
