.order-comments {

  &__list {
    max-height: 400px;
    overflow: hidden auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
      background: #e8e8e8;
    }

    &::-webkit-scrollbar-thumb {
      background: #c9c9c9;
      -webkit-border-radius: 1ex;
      -webkit-box-shadow: 0 1px 2px rgba(0,0,0, 0.25);
    }
  }

  &__item {
    margin-bottom: 20px;
  }

  &__note {
    background: #FAFAFA;
    display: block;
    padding: 10px;
    position: relative;
    margin-bottom: 10px;
    line-height: 1.8;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 20px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0 0;
      border-style: solid;
      border-color: #FAFAFA transparent;
    }
  }

  &__date {
    &--text {
      font-style: italic;
      line-height: 1;
    }
  }
}

.coupon {
  &-item {
    display: flex;
    justify-content: flex-end;

    span {
      display: block;
      margin-right: 5px;
    }
  }
}

