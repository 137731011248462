.filter-sorting {
  margin-bottom: 20px;

  &__title {
    display: block;
    color: rgba(0,0,0,.85);
    margin-bottom: 5px;
  }

  &__item {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__icon {
    font-size: 20px;
    cursor: pointer;

    &.anticon {
      color: #a3a3a3;

      &.active {
        color: #1890FF;
      }
    }

    &:not(:last-child) {
      margin-right: 10px;
    }

    &--date {
      font-size: 12px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }

  &__date {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
