.form-table {
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  width: 100%;

  &__head {
    background-color: #FAFAFA;

    &--th {
      padding: 20px;
      font-weight: 400;
      text-align: left;

      &:not(:last-child) {
        border-right: 1px solid #f0f0f0;
      }
    }
  }

  &__row {
    margin-bottom: 20px;

    .ant-row {
      display: flex;
      justify-content: space-between;
    }
  }

  &__col {
    padding: 20px;
    border-bottom: 1px solid #f0f0f0;

    &:not(:last-child) {
      border-right: 1px solid #f0f0f0;
    }


    .ant-form-item {
      margin: 0;
    }

  }
}

.stock-table {
  &.form-table {
    width: calc(100vw - 257px);
    border-bottom: 0;

    .form-table {
      &__row {
        display: flex;
        margin-bottom: 0;
      }

      &__col {
        width: 33.33%;
      }
    }
  }
}
