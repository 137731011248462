[data-theme='compact'] .site-collapse-custom-collapse,
.site-collapse-custom-collapse {
  background-color: transparent;
  border: 0;

  .site-collapse-custom-panel {
    margin-bottom: 10px;
    overflow: hidden;
    background-color: #f2f2f2;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
}
