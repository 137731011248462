body .picklist-order-popup {
  .ant-modal-body {
    padding: 0;
  }

  .not-visible {
    display: none;
  }

  .default-picklist-table {
    display: none;
  }
}
