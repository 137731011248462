.saw-info {

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .multiple-select {
    min-width: 200px;
  }

  &__item {
    width: 100%;

    &--label {
      min-width: 100px;
      padding-right: 20px;
    }
  }
}
