.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  min-height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-table-row .draggable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  font-size: 1.2rem;
  transform: rotate(90deg);
}


.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}