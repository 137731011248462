body .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  border-bottom-color: #ffffff;
}

body .react-datepicker {
  &-wrapper {
    width: unset;
  }

  &__navigation-icon::before {
    top: 13px;
  }

  &__header {
    background: transparent;
  }

  &__input-container {
    input {
      border: 0;
      max-width: 90px;

      &:focus {
        outline: none;
      }
    }
  }

  &__day {
    &:hover {
      background: #f5f5f5;
    }

    &--selected {
      background: #1890FF;
    }
  }

  &__row {
    border: 1px solid #d3d3d3;
    border-radius: 3px;
    padding: 4px 11px;

    .ant-picker {
      padding: 0;
      border: 0;

      &.ant-picker-focused {
        box-shadow: none;
      }
    }
  }
}
