.images {
  &-grid {
    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__col {
      position: relative;

      &:hover {
        .images-actions {
          opacity: 1;
        }
      }

      .ant-skeleton {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-actions {
    position: absolute;
    z-index: 3;
    width: calc(100% - 16px);
    height: 100%;
    top: 0;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    color: #fff;
    background: rgba(0,0,0,.5);
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s;
    padding: 15px;

    a {
      line-height: 1;
    }

    .anticon {
      margin-left: 15px;
    }

    svg {
      fill: #ffffff;
    }

    &__title {
      position: absolute;
      bottom: 10px;
      left: 0;
      padding: 0 10px;
      width: 100%;
      text-align: center;
      color: #ffffff;
    }
  }
}
